import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "catalog-service-card" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "service-card-info" }
const _hoisted_4 = { class: "card-title" }
const _hoisted_5 = { class: "card-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_repair_cars_modal = _resolveComponent("repair-cars-modal")!
  const _component_repair_parts_modal = _resolveComponent("repair-parts-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", {
        class: "service-img",
        src: _ctx.img,
        alt: ""
      }, null, 8, _hoisted_2),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.title), 1),
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.text), 1),
        _createVNode(_component_router_link, {
          to: _ctx.url,
          class: "detail",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$store.dispatch('setServiceItemID', _ctx.id)))
        }, {
          default: _withCtx(() => [
            _createTextVNode("Подробнее")
          ]),
          _: 1
        }, 8, ["to"]),
        _createElementVNode("button", {
          class: "forms-btn service-card-btn",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.showModal && _ctx.showModal(...args)))
        }, " Отправить заявку ")
      ])
    ]),
    _withDirectives(_createVNode(_component_repair_cars_modal, {
      product_title: _ctx.title,
      onClose: _ctx.closeModal
    }, null, 8, ["product_title", "onClose"]), [
      [_vShow, _ctx.repairCarsModalVisibility]
    ]),
    _withDirectives(_createVNode(_component_repair_parts_modal, { onClose: _ctx.closeModal }, null, 8, ["onClose"]), [
      [_vShow, _ctx.repairPartsModalVisibility]
    ])
  ], 64))
}