import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "catalog-main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CatalogTechCard = _resolveComponent("CatalogTechCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (category) => {
      return (_openBlock(), _createBlock(_component_CatalogTechCard, {
        key: category.id,
        title: category.title,
        img: category.image,
        slug: 
        category.title === 'Трактора'
          ? 'traktora'
          : category.title === 'Бульдозеры'
          ? 'buldozery'
          : category.title === 'Сваебои/Копры'
          ? 'svaeboi'
          : category.title === 'Трубоукладчики'
          ? 'truboukladchiki'
          : category.title === 'Бурильные машины'
          ? 'burilnaya-mashina'
          : null
      ,
        url: 
        '/catalog/' +
        (category.title === 'Трактора'
          ? 'traktora'
          : category.title === 'Бульдозеры'
          ? 'buldozery'
          : category.title === 'Сваебои/Копры'
          ? 'svaeboi'
          : category.title === 'Трубоукладчики'
          ? 'truboukladchiki'
          : category.title === 'Бурильные машины'
          ? 'burilnaya-mashina'
          : null)
      ,
        id: category.id
      }, null, 8, ["title", "img", "slug", "url", "id"]))
    }), 128))
  ]))
}