import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "back",
  id: "TechCards"
}
const _hoisted_2 = {
  key: 0,
  style: {"padding":"0 8px"}
}
const _hoisted_3 = { class: "leas-title" }
const _hoisted_4 = {
  key: 0,
  class: "catalog-service"
}
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_TechCard = _resolveComponent("TechCard")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.breadcrumbs, (item, idx) => {
          return (_openBlock(), _createElementBlock("span", {
            key: item.id
          }, [
            _createVNode(_component_router_link, {
              to: item.link
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(item.title), 1)
              ]),
              _: 2
            }, 1032, ["to"]),
            (idx + 1 !== _ctx.breadcrumbs.length)
              ? (_openBlock(), _createElementBlock("span", _hoisted_2, "/"))
              : _createCommentVNode("", true)
          ]))
        }), 128))
      ])
    ]),
    _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.title), 1),
    (_ctx.cars.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cars, (car) => {
            return (_openBlock(), _createBlock(_component_TechCard, {
              key: car.id,
              title: car.title,
              img: car.image,
              allowLeasing: car.allow_leasing,
              inStock: car.in_stock,
              text: car.text_description,
              url: 
        '/catalog/' + _ctx.$route.params.idCarCat + '/' + _ctx.convertLetters(car.title)
      ,
              id: car.id
            }, null, 8, ["title", "img", "allowLeasing", "inStock", "text", "url", "id"]))
          }), 128))
        ]))
      : (_openBlock(), _createElementBlock("h3", _hoisted_5, "К сожалению, в данной категории нет товаров"))
  ], 64))
}