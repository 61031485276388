<template>
  <div>404</div>
  <div>Не найдено</div>
</template>
<script lang="ts">
import { Options, Vue } from "vue-class-component";
@Options({})
export default class NotFound extends Vue {}
</script>
<style scoped></style>
