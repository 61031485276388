import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "parts-card-title" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: _ctx.url,
    class: "sub-parts-card",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$store.dispatch('setPartsSubCategoryID', _ctx.id)))
  }, {
    default: _withCtx(() => [
      _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.title), 1),
      _createElementVNode("img", {
        class: "parts-card-img",
        src: _ctx.img,
        alt: ""
      }, null, 8, _hoisted_2)
    ]),
    _: 1
  }, 8, ["to"]))
}