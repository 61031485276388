import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: _ctx.url,
    class: "catalog-parts-card",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$store.dispatch('setPartsCategoryID', _ctx.id)))
  }, {
    default: _withCtx(() => [
      _createElementVNode("h3", {
        class: _normalizeClass(["parts-card-title", _ctx.slug])
      }, _toDisplayString(_ctx.title), 3),
      _createElementVNode("img", {
        class: _normalizeClass(["parts-card-img", _ctx.slug]),
        src: _ctx.img,
        alt: ""
      }, null, 10, _hoisted_1)
    ]),
    _: 1
  }, 8, ["to"]))
}