import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "component-card" }
const _hoisted_2 = { class: "component-card-top" }
const _hoisted_3 = { class: "status" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "service-card-info" }
const _hoisted_6 = { class: "card-title" }
const _hoisted_7 = { class: "compatibility" }
const _hoisted_8 = { class: "article-number" }
const _hoisted_9 = { class: "card-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_request_tech = _resolveComponent("request-tech")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.cart.findIndex((el) => el.title === _ctx.title) === -1)
          ? (_openBlock(), _createElementBlock("a", {
              key: 0,
              class: "favourite-btn",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.addToCart && _ctx.addToCart(...args)))
            }, "В избранное"))
          : (_openBlock(), _createElementBlock("a", {
              key: 1,
              class: "favourite-btn-added",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.addToCart && _ctx.addToCart(...args)))
            }, "В избранном")),
        _withDirectives(_createElementVNode("span", _hoisted_3, "В наличии", 512), [
          [_vShow, _ctx.inStock]
        ])
      ]),
      _createElementVNode("img", {
        class: "component-card-img",
        src: _ctx.img,
        alt: ""
      }, null, 8, _hoisted_4),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("h3", _hoisted_6, _toDisplayString(_ctx.title), 1),
        _createElementVNode("span", _hoisted_7, "Совместим с: " + _toDisplayString(_ctx.compatibility), 1),
        _createElementVNode("span", _hoisted_8, "Артикул: " + _toDisplayString(_ctx.article_number), 1),
        _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.text.slice(0, 120) + "..."), 1)
      ]),
      _createVNode(_component_router_link, {
        to: _ctx.url,
        class: "detail",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$store.dispatch('setPartsItemID', _ctx.id)))
      }, {
        default: _withCtx(() => [
          _createTextVNode("Подробнее")
        ]),
        _: 1
      }, 8, ["to"]),
      _createElementVNode("button", {
        class: "forms-btn service-card-btn",
        onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.showModalPrice && _ctx.showModalPrice(...args)))
      }, " Купить ")
    ]),
    _withDirectives(_createVNode(_component_request_tech, {
      onClose: _ctx.closeModalPrice,
      product_title: _ctx.title,
      type: "part"
    }, null, 8, ["onClose", "product_title"]), [
      [_vShow, _ctx.repairPriceModalVisibility]
    ])
  ], 64))
}