<template>
  <div class="payment">
    <h2 class="home-title">ОПЛАТА И ДОСТАВКА</h2>
    <div class="payment-info" v-html="text"></div>
    <!--      <h3>ДОСТАВКА</h3>
      <h4>Спецтехника</h4>
      ТД “Челябинские строительные машины” организует доставку товаров по всей
      территории России. <br />Варианты доставки: <br />
      Мы организуем доставку приобретенной у нас спецтехники (оборудования)
      силами третьих лиц. ООО много лет сотрудничает с проверенными
      транспортными перевозчиками, которые доставляют приобретенную у нас
      спецтехнику покупателям автомобильным транспортом, ж/д транспортом. Кроме
      того, организуем погрузку приобретенной у нас спецтехники на транспорт и
      ее увязку.
      <h4>Запчасти</h4>
      ТД “Челябинские строительные машины” выполняет доставку товаров по всей
      территории России. <br />Варианты доставки: <br />
      1. Если запчасть (запчасти) для спецтехники не является
      крупногабаритной(не требуется индивидуальная перевозка автомобильным
      транспортом), то при заказе на сумму от 30 000 руб., но весом не более 1
      тонны, доставка заказа от склада ООО до транспортной компании
      осуществляется бесплатно. Далее по тарифам ТК за счет покупателя. Расчет
      произведем в процессе оформления Вами заказа.
    </div>-->
  </div>
</template>
<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
  data() {
    return {
      text: "",
    };
  },
  mounted() {
    fetch("https://chelstroymash.ru/api/delivery-main/")
      .then((res) => res.json())
      .then((data) => (this.text = data[0].text));
  },
})
export default class Payment extends Vue {}
</script>
<style>
.payment {
  padding: 0 32px;

  margin: 0 auto;

  .payment-info {
    text-align: left;
    margin-top: 36px;
    font-size: 24px;
  }
}
@media (max-width: 1600px) {
  .payment .payment-info {
    font-size: 18px;
  }
}
@media (max-width: 1199px) {
  .payment .payment-info {
    font-size: 14px;
  }
}
</style>
