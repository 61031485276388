import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d863a1de"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mobile-menu" }
const _hoisted_2 = { class: "mobile-menu-list" }
const _hoisted_3 = { class: "mobile-menu-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      _createElementVNode("li", _hoisted_3, [
        _createVNode(_component_router_link, {
          to: "/catalog",
          class: "menu-link",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
        }, {
          default: _withCtx(() => [
            _createTextVNode("СПЕЦТЕХНИКА")
          ]),
          _: 1
        })
      ]),
      _createElementVNode("li", {
        class: "mobile-menu-item",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
      }, [
        _createVNode(_component_router_link, {
          to: "/leasing",
          class: "menu-link"
        }, {
          default: _withCtx(() => [
            _createTextVNode("ЛИЗИНГ")
          ]),
          _: 1
        })
      ]),
      _createElementVNode("li", {
        class: "mobile-menu-item",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('close')))
      }, [
        _createVNode(_component_router_link, {
          to: "/parts",
          class: "menu-link"
        }, {
          default: _withCtx(() => [
            _createTextVNode("ЗАПЧАСТИ")
          ]),
          _: 1
        })
      ]),
      _createElementVNode("li", {
        class: "mobile-menu-item",
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('close')))
      }, [
        _createVNode(_component_router_link, {
          to: "/services",
          class: "menu-link"
        }, {
          default: _withCtx(() => [
            _createTextVNode("РЕМОНТ СПЕЦТЕХНИКИ")
          ]),
          _: 1
        })
      ]),
      _createElementVNode("li", {
        class: "mobile-menu-item",
        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('close')))
      }, [
        _createVNode(_component_router_link, {
          to: "/about",
          class: "menu-link"
        }, {
          default: _withCtx(() => [
            _createTextVNode("О КОМПАНИИ")
          ]),
          _: 1
        })
      ]),
      _createElementVNode("li", {
        class: "mobile-menu-item",
        onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('close')))
      }, [
        _createVNode(_component_router_link, {
          to: "/paymentAndDelivery",
          class: "menu-link"
        }, {
          default: _withCtx(() => [
            _createTextVNode("ОПЛАТА И ДОСТАВКА")
          ]),
          _: 1
        })
      ]),
      _createElementVNode("li", {
        class: "mobile-menu-item",
        onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('close')))
      }, [
        _createVNode(_component_router_link, {
          to: "/favourites",
          class: "menu-link"
        }, {
          default: _withCtx(() => [
            _createTextVNode("ИЗБРАННОЕ")
          ]),
          _: 1
        })
      ]),
      _createElementVNode("li", {
        class: "mobile-menu-item",
        onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit('close')))
      }, [
        _createVNode(_component_router_link, {
          to: "/contacts",
          class: "menu-link"
        }, {
          default: _withCtx(() => [
            _createTextVNode("КОНТАКТЫ")
          ]),
          _: 1
        })
      ])
    ])
  ]))
}