import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "sub-parts" }
const _hoisted_2 = { class: "back" }
const _hoisted_3 = {
  key: 0,
  style: {"padding":"0 8px"}
}
const _hoisted_4 = { class: "parts-title" }
const _hoisted_5 = { class: "catalog-main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_SubPartsCard = _resolveComponent("SubPartsCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.breadcrumbs, (item, idx) => {
          return (_openBlock(), _createElementBlock("span", {
            key: item.id
          }, [
            _createVNode(_component_router_link, {
              to: item.link
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(item.title), 1)
              ]),
              _: 2
            }, 1032, ["to"]),
            (idx + 1 !== _ctx.breadcrumbs.length)
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, "/"))
              : _createCommentVNode("", true)
          ]))
        }), 128))
      ])
    ]),
    _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.categoryTitle), 1),
    _createElementVNode("div", _hoisted_5, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.subcategories, (item) => {
        return (_openBlock(), _createBlock(_component_SubPartsCard, {
          key: item.id,
          url: '/parts/' + _ctx.$route.params.id + '/' + _ctx.convertLetters(item.title),
          img: item.image,
          title: item.title,
          id: item.id
        }, null, 8, ["url", "img", "title", "id"]))
      }), 128))
    ])
  ]))
}